<template>
  <el-carousel :height="height" arrow="never" :autoplay="false" indicator-position="none">
    <el-carousel-item>
      <div
        class="bg-img w-full h-[460px] laptop:h-[920px] desktop:h-[100vh] laptop:pl-[85px] tablet:pl-[46px] bg-cover bg-center text-white flex flex-col desktop:items-center desktop:justify-center text-start"
      >
        <div
          class="tablet:mt-[52px] laptop:mt-[90px] tablet:mb-[12px] laptop:mb-[24px] desktop:mb-[30px] flex flex-col desktop:flex-row desktop:items-center"
        >
          <img
            class="tablet:w-[83px] tablet:h-[20px] laptop:w-[166px] laptop:h-[40px] tablet:mb-[12px] laptop:mb-[24px] desktop:h-[77px] desktop:mr-[20px]"
            src="./../assets/images/BlueOS-white.png"
            alt="BlueOS Logo"
          />
          <h1
            class="whitespace-nowrap tablet:text-[30px] tablet:leading-[30px] laptop:text-[60px] laptop:leading-[76px] desktop:text-[88px] desktop:leading-[88px] font-medium desktop:font-bold"
          >
            蓝河操作系统
          </h1>
        </div>
        <div
          class="tablet:w-[252px] tablet:mb-[12px] laptop:w-[554px] laptop:mb-[39px] desktop:mb-[40px] laptop:text-[28px] desktop:text-[28px] tablet:text-[14px] tablet:leading-[24px] laptop:leading-[42px] desktop:leading-[40px]"
        >
          vivo 面向通用人工智能时代自主研发的智慧操作系统
        </div>
        <a
          class="outline-none flex justify-center items-center tablet:w-[80px] tablet:h-[30px] laptop:w-[160px] laptop:h-[60px] desktop:w-[176px] desktop:h-[52px] tablet:text-[12px] laptop:text-[24px] desktop:text-[20px] rounded-[30px] desktop:rounded-[26px] border border-white hover:border-[#2085FF] bg-[rgba(255,255,255,0.20)] hover:bg-[#2085FF]"
          href="https://developers.vivo.com/product/blueOpen/ability"
          target="_blank"
        >
          开放能力
        </a>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: 'TopBanner',
  data() {
    return {
      height: '100vh'
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize() // 初始化时调用一次
  },
  methods: {
    handleResize() {
      let screenWidth = window.innerWidth
      // 根据屏幕宽度计算height值
      if (screenWidth >= 1440) {
        this.height = '100vh'
      } else if (screenWidth >= 768) {
        this.height = '920px'
      } else {
        this.height = '460px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bg-img {
  @media screen and (min-width: 320px) {
    background-image: url('./../assets/images/banner-mobile.jpg');
  }

  @media screen and (min-width: 767px) {
    background-image: url('./../assets/images/banner-mobile.jpg');
  }

  @media screen and (min-width: 1440px) {
    background-image: url('./../assets/images/banner-pc.jpg');
  }
}
</style>
