<template>
  <section
    class="section-container w-full bg-[#FAFBFC] desktop:bg-white flex flex-col items-start desktop:items-center tablet:px-[16px] laptop:px-[32px]"
  >
    <img
      class="tablet:mt-[30px] laptop:mt-[58px] desktop:mt-[160px] tablet:mb-[16px] laptop:mb-[34px] desktop:mb-[80px] tablet:h-[26px] laptop:h-[46px] desktop:h-[80px]"
      src="./../assets/images/core-features.png"
      alt="BlueOS Logo"
    />
    <div
      class="card1 w-full desktop:w-[1200px] mb-[16px] laptop:mb-[32px] desktop:mb-[60px] bg-white rounded-[20px] desktop:rounded-[30px] flex flex-col desktop:flex-row items-center"
    >
      <div class="text-left desktop:my-auto mx-[24px] laptop:mx-[60px] desktop:ml-[75px] desktop:mr-[35px]">
        <h3
          class="tablet:text-[20px] text-[58px] tablet:leading-[32px] leading-[58px] vivo-blod-font font-medium mb-[16px] laptop:mb-[20px] desktop:mb-[24px] mt-[24px] laptop:mt-[60px] desktop:mt-[0] text-[#1D1D1F]"
        >
          天生更<span class="text-[#295AFF]">智慧</span>
        </h3>
        <div
          class="tablet:text-[14px] text-[20px] tablet:leading-[24px] leading-[40px] text-[#86868B] desktop:text-[#666666] mb-[10px] desktop:mb-[0]"
        >蓝河操作系统，从系统、应用、到工具链全面突破，基于 AI 大模型能力实现 AI 服务引擎和多模输入子系统，带来了多模态输入输出，模拟人与人的交互方式。基于 AI 能力探索出应用开发新范式，打造了诸多智慧操作系统的新型应用。未来将开放 Copilot 工具，提供代码生成、图文生成等能力，带来应用开发的全新生产力工具。
        </div>
      </div>
      <img
        class="w-[294px] h-[248px] laptop:w-[566px] laptop:h-[479px] desktop:w-[710px] desktop:h-[600px] mb-[20px] desktop:mb-0"
        src="./../assets/images/wisdom.png"
        alt=""
      />
    </div>
    <div
      class="card2 w-full desktop:w-[1200px] mb-[16px] laptop:mb-[32px] desktop:mb-[60px] bg-white rounded-[20px] desktop:rounded-[30px] flex flex-col-reverse desktop:flex-row items-center"
    >
      <img
        class="w-[294px] h-[248px] laptop:w-[566px] laptop:h-[479px] desktop:w-[710px] desktop:h-[600px] mb-[20px] desktop:mb-0"
        src="./../assets/images/fluency.png"
        alt=""
      />
      <div class="text-left desktop:my-auto mx-[24px] laptop:mx-[60px] desktop:ml-[35px] desktop:mr-[75px]">
        <h3
          class="tablet:text-[20px] text-[58px] tablet:leading-[32px] leading-[58px] vivo-blod-font font-medium mb-[16px] laptop:mb-[20px] desktop:mb-[24px] mt-[24px] laptop:mt-[60px] desktop:mt-[0] text-[#1D1D1F] desktop:text-right"
        >
          天生更<span class="text-[#295AFF]">流畅</span>
        </h3>
        <div
          class="tablet:text-[14px] text-[20px] tablet:leading-[24px] leading-[40px] text-[#86868B] desktop:text-[#666666] mb-[10px] desktop:mb-[0]"
        >蓝河操作系统从全栈技术视角出发，对多个技术方向进行探索，例如编程语言、运行时 Runtime、系统调度、显示和内存。充分发挥软硬件资源的利用效率，高性能系统架构实现了一系列关键技术，虚拟显卡框架、超级协程机制、Runtime 等，提升了计算、存储、显示的资源效率。
        </div>
      </div>
    </div>
    <div
      class="card3 w-full desktop:w-[1200px] mb-[16px] laptop:mb-[32px] desktop:mb-[160px] bg-white rounded-[20px] desktop:rounded-[30px] flex flex-col desktop:flex-row items-center"
    >
      <div class="text-left desktop:my-auto mx-[24px] laptop:mx-[60px] desktop:ml-[75px] desktop:mr-[35px]">
        <h3
          class="tablet:text-[20px] text-[58px] tablet:leading-[32px] leading-[58px] vivo-blod-font font-medium mb-[16px] laptop:mb-[20px] desktop:mb-[24px] mt-[24px] laptop:mt-[60px] desktop:mt-[0] text-[#1D1D1F]"
        >
          天生更<span class="text-[#295AFF]">安全</span>
        </h3>
        <div
          class="tablet:text-[14px] text-[20px] tablet:leading-[24px] leading-[40px] text-[#86868B] desktop:text-[#666666] mb-[10px] desktop:mb-[0]"
        >蓝河操作系统从性能和安全两个维度选择了 Rust 语言作为系统开发语言，Rust 语言的所有权模型、生命周期等一系列安全特性，保障了在编译阶段就可以发现内存使用不当导致的安全漏洞。蓝河操作系统是行业首个系统框架由 Rust 语言编写的操作系统，从源头保障了内存安全和并发安全。
        </div>
      </div>
      <img
        class="w-[294px] h-[248px] laptop:w-[566px] laptop:h-[479px] desktop:w-[710px] desktop:h-[600px] mb-[20px] desktop:mb-0"
        src="./../assets/images/security.png"
        alt=""
      />
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { isSmallerScreen } from './../helper/utils'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'CoreFeatures',
  created() {
    this.$nextTick(() => {
      if (isSmallerScreen()) return

      this.addDynamicAnimate()
    })
  },
  methods: {
    addDynamicAnimate() {
      gsap.from('.card1', {
        scrollTrigger: {
          trigger: '.card1',
          markers: false,
          start: 'top 80%',
          end: 'bottom 200px',
          toggleActions: 'restart none none reverse'
        },
        duration: 0.5,
        y: 100,
        autoAlpha: 0
      })
      gsap.from('.card2', {
        scrollTrigger: {
          trigger: '.card2',
          markers: false,
          start: 'top 80%',
          end: 'bottom 200px',
          toggleActions: 'restart none none reverse'
        },
        duration: 0.5,
        y: 100,
        autoAlpha: 0
      })
      gsap.from('.card3', {
        scrollTrigger: {
          trigger: '.card3',
          markers: false,
          start: 'top 80%',
          end: 'bottom 200px',
          toggleActions: 'restart none none reverse'
        },
        duration: 0.5,
        y: 100,
        autoAlpha: 0
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card1 {
  @media screen and (min-width: 1440px) {
    background-image: url('./../assets/images/card1.png');
  }
}

.card2 {
  @media screen and (min-width: 1440px) {
    background-image: url('./../assets/images/card2.png');
  }
}

.card3 {
  @media screen and (min-width: 1440px) {
    background-image: url('./../assets/images/card3.png');
  }
}
</style>
