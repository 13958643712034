<template>
  <div class="page-container">
    <TopBanner />
    <CoreFeatures />
    <SystemArch />
    <KeyTech />
    <BlueDevEco />
    <WatchFace />
    <GenFace />
    <WatchExperience />
    <BlueXLink />
    <Elevator />
    <Footer />
  </div>
</template>

<script>
import { isMobile, debounce } from './../helper/utils'
import TopBanner from './../components/TopBanner.vue'
import CoreFeatures from './../components/CoreFeatures.vue'
import SystemArch from './../components/SystemArch.vue'
import KeyTech from './../components/KeyTech.vue'
import BlueDevEco from './../components/BlueDevEco.vue'
import WatchFace from './../components/WatchFace.vue'
import GenFace from './../components/GenFace.vue'
import WatchExperience from './../components/WatchExperience.vue'
import BlueXLink from './../components/BlueXLink.vue'
import Footer from './../components/Footer.vue'
import Elevator from './../components/Elevator.vue'

export default {
  name: 'Home',

  data() {
    return {}
  },

  components: {
    TopBanner,
    CoreFeatures,
    SystemArch,
    KeyTech,
    BlueDevEco,
    WatchFace,
    GenFace,
    BlueXLink,
    WatchExperience,
    Footer,
    Elevator
  },

  mounted() {
    this.handleScreenChanges()
    this.injectVcodeTrace()
  },

  computed: {},

  methods: {
    handleScreenChanges() {
      let width = window.innerWidth
      let height = window.innerHeight
      const REFRESH_THRESHOLD_W = 20
      const REFRESH_THRESHOLD_H = 900

      const reload = debounce(() => {
        const newWidth = window.innerWidth
        const newHeight = window.innerHeight

        if (Math.abs(newWidth - width) > REFRESH_THRESHOLD_W || Math.abs(newHeight - height) > REFRESH_THRESHOLD_H) {
          location.reload()
        }
        width = newWidth
        height = newHeight
      }, 500)

      window.onresize = reload
    },

    injectVcodeTrace() {
      const params = {
        // pc端和移动端，1表示 pc，2表示移动端
        pc_move: isMobile() ? 2 : 1,
        // 记录页面来源
        source_address: document.referrer
      }
      this.$vcode.send({
        eventId: 'W814|10001',
        params
      })
    }
  }
}
</script>
