<template>
  <footer class="w-full bg-white flex flex-col items-center m-auto">
    <div
      class="desktop:w-[1200px] w-full desktop:h-[240px] flex flex-col justify-between desktop:py-[60px] text-greyblack"
    >
      <div class="w-full flex desktop:flex-row flex-col items-center justify-around">
        <div class="desktop:w-2/3 w-full flex desktop:flex-row flex-col items-start justify-between">
          <ul class="footer-col tablet:px-[16px] laptop:px-[32px]" v-for="item in footerInfoArr" :key="item.title">
            <li
              class="item w-full tablet:h-full laptop:h-full desktop:text-brand desktop:vivo-blod-font flex justify-between items-center tablet:cursor-pointer laptop:cursor-pointer desktop:text-[16px] text-[14px] desktop:mb-[20px]"
              @click="onItemClick"
            >
              {{ item.title }}
              <img class="to-bottom desktop:hidden" src="./../assets/svg/bottom.svg" />
            </li>
            <li
              class="item flex justify-between items-center text-[14px] cursor-pointer text-greyblack hover:text-blue leading-[30px]"
              v-for="citem in item.branch"
              :key="citem.title"
              @click="onSubItemClick(citem.url)"
            >
              {{ citem.title }}
            </li>
          </ul>
        </div>
        <div class="desktop:w-1/3 w-full h-full desktop:flex block flex-row items-start justify-end desktop:space-x-2">
          <img class="laptop:hidden tablet:hidden w-[32px]" src="./../assets/svg/contact.svg" alt="BlusOS 联系方式" />
          <ul class="footer-col tablet:px-[16px] laptop:px-[32px]">
            <li
              class="item w-full tablet:h-[80px] laptop:h-[80px] desktop:text-brand desktop:vivo-blod-font flex justify-between items-center tablet:cursor-pointer laptop:cursor-pointer desktop:text-[16px] text-[14px] desktop:mb-[20px]"
              @click="onItemClick"
            >
              联系我们
              <img class="to-bottom desktop:hidden" src="./../assets/svg/contact.svg" alt="BlusOS 联系方式" />
            </li>
            <li class="item flex justify-between items-center">Email：blueos@vivo.com</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-full flex items-center justify-center font-extralight font-sans bg-darkgrey">
      <div class="w-full tablet:hidden laptop:hidden py-[20px] text-[14px] text-center text-greyblack">
        &copy;2011-{{ currentYear }} 维沃移动通信有限公司 版权所有 |
        <a class="outline-none" href="https://www.vivo.com.cn/about-vivo/legal" target="_blank">法律声明</a>
        |
        <a class="outline-none" href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
          {{ filingNum }}
        </a>
        |
        <a
          class="outline-none"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002001972"
          target="_blank"
          rel="noopener noreferrer"
        >
          粤公网安备 44190002001972号
        </a>
      </div>
      <div class="desktop:hidden w-full py-[10px] text-[12px] text-greyblack text-center">
        &copy;2011-{{ currentYear }} 维沃移动通信有限公司
        <br />
        版权所有
        <a class="tablet:hidden" href="https://www.vivo.com.cn/about-vivo/privacy-policy" target="_blank">| 隐私政策</a>
        |
        <a href="https://www.vivo.com.cn/about-vivo/legal" target="_blank">法律声明</a>
        <br />
        <a href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">{{ filingNum }}</a>
        |
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002001972"
          target="_blank"
          rel="noopener noreferrer"
        >
          粤公网安备 44190002001972号
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
const footerInfoArr = [
  {
    title: '支持与服务',
    branch: [
      {
        title: '文档中心',
        url: 'https://developers.vivo.com/product/blueOpen/ability'
      },
      {
        title: '开发者助手',
        url: 'https://developers.vivo.com/support/'
      },
      {
        title: '项目合作',
        url: 'https://developers.vivo.com/doc/d/a74d0ce7896bd3a9a143691aecfe2fa9'
      }
    ]
  },
  {
    title: '友情链接',
    branch: [
      {
        title: 'vivo 官网',
        url: 'http://www.vivo.com.cn/'
      },
      {
        title: 'vivo 办公套件',
        url: 'http://zs.vivo.com.cn/'
      },
      {
        title: 'vivo 开放平台',
        url: 'https://dev.vivo.com.cn/home'
      },
      {
        title: 'vivo IoT 开发者平台',
        url: 'https://iot.vivo.com.cn/home'
      }
    ]
  },
  {
    title: '关于 vivo',
    branch: [
      {
        title: 'vivo 简介',
        url: 'https://www.vivo.com.cn/brand/about'
      },
      {
        title: '工作机会',
        url: 'https://hr.vivo.com/wt/vivo/web/index/CompvivoPageindex'
      }
    ]
  }
]

export default {
  data() {
    return {
      footerInfoArr: Object.freeze(footerInfoArr),
      currentActive: null
    }
  },

  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    filingNum() {
      const isBlueOS = location.hostname.includes('blueos.com.cn')
      return isBlueOS ? '粤ICP备17035209号-19' : '粤ICP备17035209号'
    }
  },

  methods: {
    onItemClick(e) {
      const k = e.target.parentElement
      if (e.target.className === 'to-bottom') {
        k = k.parentElement
      }
      if (this.currentActive) this.currentActive.classList.remove('active')
      if (this.currentActive === k) {
        this.currentActive = null
        return
      }
      this.currentActive = k
      k.classList.add('active')
    },

    onSubItemClick(i) {
      if (/^(http|https)/.test(i)) {
        window.open(i)
      } else {
        window.location.href = window.location.origin + i
      }
    }
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 1439px) {
  .footer-col {
    width: 100%;
    height: 40px;
    border-top: 1px solid #ebedf0;
    overflow: hidden;
    transition: all 0.8s;
    &.active {
      max-height: 256px;
      height: auto;
      transition: all 0.8s;
    }
    .item {
      height: 40px;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
    }
  }
  .to-bottom {
    display: block;
    width: 16px;
    height: 16px;
    transition: all 0.8s;
  }
  .active {
    .to-bottom {
      transform: rotateX(180deg);
    }
  }
}
</style>
