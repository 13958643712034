import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/app.css'
import vcode from '@vivo/vcode-sdk'
import 'element-ui/lib/theme-chalk/index.css'
import { CarouselItem, Carousel } from 'element-ui'

// 埋点初始化
vcode.init(
  {
    mid: 'W814', // vcode后台申请的模块id, 必填
    mvs: '1', // vcode后台申请的模块版本号, 必填
    mvn: '0.0.0.1', // vcode后台申请的模块版本名, 必填
    cm: 'CN-ZH', // 国家码, 必填
    area: 'CN', // 区域码, 外销必填, 使用区域码区分外销上报域名
    transport: 'beacon', // 可选, 默认'beacon', 上报方法: 'beacon', 'xhr'
    alwaysReport: false, // 可选, 默认false, 是否离开上报未配置事件
    throttleDelay: 120 // 可选, 默认120, 自定义上报节流时间间隔
  },
  {
    isDebug: false, // 可选, 默认false, 是否开启调试模式
    isUseCache: false // 可选, 默认false, 是否使用配置缓存
  }
)

Vue.prototype.$vcode = vcode
Vue.config.productionTip = false
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Carousel.name, Carousel)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
