<template>
  <section id="system-arch" class="w-full bg-white desktop:bg-silverwhile">
    <div class="module-warpper module-space">
      <h2 class="large-title vivo-blod-font font-medium">系统架构</h2>
      <div class="general-desc py-[36px] tablet:py-[20px] text-left" v-html="systemArchTesc"></div>
    </div>

    <!-- For desktop -->
    <div id="sa-animate-trigger" class="relative laptop:hidden tablet:hidden">
      <div class="w-full h-[55.6vw] flex justify-center items-center relative">
        <div class="img-container w-[62.5vw] h-[35.156vw] rounded-3xl flex flex-col overflow-hidden">
          <img
            class="dynamic-img block w-[100vw] h-full relative"
            style="translate: none; rotate: none; scale: none; transform: scale(1, 1)"
            src="./../assets/images/system-arch.jpg"
            alt="系统架构"
          />
          <span class="stop-marker"></span>
        </div>
      </div>
    </div>
    <!-- For tablet & laptop  -->
    <div class="desktop:hidden w-full tablet:px-[16px] laptop:px-[32px] laptop:mb-[60px] tablet:mb-[30px]">
      <img id="system-arch-img" class="block w-full" src="./../assets/images/system-arch-mobile.jpg" alt="系统架构" />
    </div>
  </section>
</template>

<script>
import mediumZoom from 'medium-zoom'
import { gsap, ScrollTrigger } from 'gsap/all'
import { isSmallerScreen } from './../helper/utils'
gsap.registerPlugin(ScrollTrigger)

const systemArchTesc = `蓝河操作系统兼容不同硬件体系结构，通过内核抽象层实现了对不同内核的抽象设计，兼容多种 Posix 标准的内核， 支持 Linux 内核，也兼容 RTOS 内核。
<p class="line">在系统层构建了9大关键能力，提供应用程序 API，实现从系统、应用，到工具链的全面突破。</p>
<p class="line">蓝河操作系统通过 AI 大模型的加持，实现了 AI 服务引擎，带来多模态交互新体验。</p>
<p class="line">蓝河操作系统也兼容了快应用技术标准，为开发者提供了蓝河开发套件，包括蓝河 SDK 和蓝河 Studio。</p>`

export default {
  name: 'SystemArch',

  data() {
    return {
      systemArchTesc
    }
  },

  created() {
    this.$nextTick(() => {
      if (isSmallerScreen()) return

      this.addDynamicAnimate()
    })
  },

  mounted() {
    if (isSmallerScreen()) {
      const targetImgNode = document.querySelector('#system-arch-img')
      if (targetImgNode) mediumZoom(targetImgNode)
    }
  },

  methods: {
    addDynamicAnimate() {
      this.trigger2scale()
      this.trigger2slide()
    },

    trigger2slide() {
      const trigger = gsap.timeline({
        scrollTrigger: {
          toggleActions: 'restart none reverse none',
          trigger: '#sa-animate-trigger',
          start: 'top 70%',
          end: 'top 70%',
          markers: false
        },
        onComplete: () => {}
      })
      trigger.fromTo(
        '#sa-animate-trigger .img-container',
        {
          y: 0
        },
        {
          y: '-4.167vw'
        }
      )
    },

    trigger2scale() {
      const trigger = gsap.timeline({
        scrollTrigger: {
          toggleActions: 'restart none reverse none',
          trigger: '#sa-animate-trigger',
          start: 'top top',
          end: '+=100%',
          markers: false,
          pin: true,
          scrub: 1
        }
      })

      trigger
        .fromTo(
          '#sa-animate-trigger .img-container',
          {
            width: '62.5vw',
            height: '35.156vw',
            borderRadius: '2.604vw'
            // y: '-4.167vw'
          },
          {
            width: '100vw',
            height: '55.6vw',
            borderRadius: 0,
            y: 0,
            duration: 8,
            ease: 'none'
          },
          '0'
        )
        .fromTo(
          '.img-container .stop-marker',
          {
            y: 0,
            opacity: 0
          },
          {
            y: 0,
            opacity: 0,
            duration: 2,
            ease: 'none'
          },
          '4'
        )
    }
  }
}
</script>

<style scoped lang="scss"></style>
