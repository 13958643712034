<template>
  <section id="dev-eco" class="w-full bg-white relative">
    <div class="module-warpper module-space">
      <img class="blueos-logo" src="./../assets/images/BlueOS-black.png" alt="BlueOS Logo" />
      <h2 class="large-title vivo-blod-font font-medium">生态介绍</h2>
    </div>

    <div
      id="eco-animate-trigger"
      class="w-full bg-white images desktop:h-[100vh] flex desktop:flex-row flex-col overflow-hidden flex-nowrap overscroll-none tablet:mt-[16px] laptop:mt-[32px]"
    >
      <div class="panel w-full desktop:h-[100vh]" v-for="item in devEcoList">
        <a
          class="desktop:cursor-auto outline-none"
          :href="isMobileFlag ? item.externalLink : 'javascript:void(0)'"
          target="_self"
          ref="noopener noreferrer"
        >
          <div
            class="w-full desktop:h-[100vh] flex flex-col justify-center desktop:items-start items-center tablet:px-[16px] laptop:px-[32px]"
          >
            <section class="section w-full desktop:aspect-[192/108] blue-eco-section-responsive">
              <h3
                class="title desktop:text-[48px] laptop:text-[32px] tablet:text-[20px] font-bold desktop:mb-[60px] tablet:mb-[10px] laptop:mb-[20px] text-black"
              >
                {{ item.title }}
              </h3>
              <div
                class="w-full flex desktop:flex-row flex-col justify-between desktop:space-x-[20px] desktop:bg-greywhile desktop:p-[50px] desktop:rounded-[40px]"
              >
                <div
                  class="desktop:w-[400px] w-full desktop:h-full flex flex-col items-start justify-center desktop:pl-[10px]"
                >
                  <div
                    class="text-silver desktop:text-[20px] laptop:text-[20px] tablet:text-[14px] desktop:leading-10 laptop:leading-8 tablet:leading-[24px] font-normal text-left tablet:mb-[10px] laptop:mb-[20px]"
                  >
                    {{ item.desc }}
                  </div>
                  <a
                    class="tablet:hidden laptop:hidden flex justify-center text-[16px] mt-[24px] items-center text-black hover:text-blue w-[128px] h-[48px] rounded-[24px] border border-silvergrey hover:border-blue outline-none"
                    :href="item.externalLink"
                    target="_blank"
                    ref="noopener noreferrer"
                  >
                    {{ item.buttonText }}
                  </a>
                </div>
                <div class="desktop:max-w-[1280px] desktop:w-[60%] w-full desktop:h-full flex flex-col">
                  <img class="block w-full" :src="item.path" :alt="item.title" />
                </div>
              </div>
            </section>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap, ScrollTrigger } from 'gsap/all'
import { isSmallerScreen } from './../helper/utils'
gsap.registerPlugin(ScrollTrigger)

const DEV_ECO_LIST = [
  {
    title: '蓝河生态',
    desc: '蓝河生态致力于打造一个让用户和开发者共赢的生态，为用户提供丰富的服务和智慧、流畅、安全的体验，为开发者提供能力支持。蓝河操作系统还兼容了快应用技术标准，助力开发者高效开发。',
    path: require('./../assets/images/eco-intro.png'),
    buttonText: '查看详情 >',
    externalLink: 'https://developers.vivo.com/product/blueOpen/ability'
  },
  {
    title: '蓝河 Studio',
    desc: '蓝河 Studio 是蓝河集成开发环境，它是一种软件应用程序，为软件开发人员提供了一种集成的开发环境，包括代码编辑器、编译器、调试器、版本控制工具、图形用户界面设计工具等。',
    path: require('./../assets/images/blue-studio.png'),
    buttonText: '查看详情 >',
    externalLink: 'https://developers.vivo.com/product/blue/blueStudio'
  },
  {
    title: '蓝河 SDK',
    desc: '蓝河 SDK 是蓝河系统软件开发工具包，是一组软件开发工具和资源的集合；用于开发蓝河应用；包括 API、库文件等。',
    path: require('./../assets/images/blue-sdk.png'),
    buttonText: '查看详情 >',
    externalLink: 'https://developers.vivo.com/product/blue/blueSdk'
  }
]

export default {
  name: 'BlueDevEco',

  data() {
    return {
      isMobileFlag: isSmallerScreen(),
      currentUrlPath: location.href,
      devEcoList: Object.freeze(DEV_ECO_LIST)
    }
  },

  created() {
    this.$nextTick(() => {
      if (isSmallerScreen()) return

      this.addDynamicAnimate()
    })
  },

  methods: {
    addDynamicAnimate() {
      const scrollWidth = document.querySelector('#eco-animate-trigger').scrollWidth
      const offset = scrollWidth - document.documentElement.clientWidth
      const panels = gsap.utils.toArray('#eco-animate-trigger .panel')

      gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: 'none',
        scrollTrigger: {
          trigger: '#eco-animate-trigger',
          pin: true,
          scrub: 1,
          markers: false,
          end: `+=${offset}`
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 1440px) {
  #eco-animate-trigger {
    padding-left: calc((100vw - 1200px) / 2);
  }
}

@media screen and (min-width: 1440px) and (max-width: 1760px) and (min-height: 900px) {
  #eco-animate-trigger {
    .panel {
      width: 1280px;
      min-width: 1280px;
      .section {
        width: 1200px;
        min-width: 1200px;
        .title {
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1760px) and (max-height: 900px) {
  #eco-animate-trigger {
    .panel {
      width: 1100px;
      min-width: 1100px;
      .section {
        width: 1000px;
        min-width: 1000px;
        .title {
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #eco-animate-trigger {
    .panel {
      min-width: 1560px;
      .section {
        width: 1440px;
        min-width: 1440px;
      }
    }
  }
}
</style>
